import React, { useEffect, useState } from 'react';

import Spinner from 'components/Spinner';

import { useMap, getLocationPoint } from './loadHelpers';
import { drawPoint, drawProjectLocations } from './graphicsHelpers';
import { getRiskScorePopupContent, getRiskLayerQuery, interactiveLayers } from './constants';
import * as locator from "@arcgis/core/rest/locator.js";

import { StyledMap, Wrapper } from './styles';

const locatorUrl = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'

const ProjectLocationMap = (props) => {
  const {
    searchInput, selectedAddress, selectedLayer, projectLocations, selectedLocation,
    onSuggestionsChange, onLocationChange, onMapClick, onMapLoad, className,
  } = props;

  const loadOptions = {
    view: {
      center: [-47, 45],
      zoom: 2,
    },
    isProjectLocation: true,
    selectedLayer,
  };

  const isInteractiveLayer = interactiveLayers.includes(selectedLayer);

  const [mapRef, mapView, layers, graphicLayers, _locatior, legend] = useMap(loadOptions);
  const [clickListener, setClickListener] = useState();
  const [querying, setQuerying] = useState(false);

  const handleOpenPopup = async (location) => {
    const { longitude, latitude, address } = location;
    let popupContent;
    if (isInteractiveLayer) {
      setQuerying(true);
      const queryResult = await layers[selectedLayer].layer
        .queryFeatures(getRiskLayerQuery(location));
      const { riskScore, riskDesc, color } = queryResult?.features[0]?.attributes;
      popupContent = `
        ${Number(latitude).toFixed(6)} ${Number(longitude).toFixed(6)}\n
        ${getRiskScorePopupContent(selectedLayer, riskScore, riskDesc, color)}
      `;
    } else {
      popupContent = `${Number(latitude).toFixed(6)} ${Number(longitude).toFixed(6)}`;
    }
    setQuerying(false);
    mapView.popup.open({
      location: { longitude, latitude },
      title: address,
      content: popupContent,
    });
  };

  // handling map clicks
  const applyClickHandlerToView = async () => {
    if (clickListener) {
      clickListener.remove();
    }
    const listener = mapView.on('click', async (event) => {
      const params = {
        location: event.mapPoint,
      };
      const { results } = await mapView.hitTest(event);
      const locationClickResult = results.find((result) => result.graphic.layer.id === 'locations');
      const isClickOnLocation = !!locationClickResult;
      if (!isClickOnLocation) {
        try {
          const locationInfo = await locator.locationToAddress(locatorUrl, params);
          const {
            attributes: { LongLabel: address, CountryCode: countryCode, Region: state, City: city },
            location: { latitude, longitude },
          } = locationInfo;

          onMapClick({
            address,
            countryCode,
            state,
            city,
            latitude,
            longitude,
          });
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.log('locationToAddress error: ', error); // eslint-disable-line no-console
          }
        }
      } else {
        graphicLayers.points.removeAll();
        const locationData = locationClickResult.graphic.attributes;
        const { address, coordinates: { longitude, latitude } } = locationData;
        handleOpenPopup({ longitude, latitude, address });
      }
    });
    setClickListener(listener);
  };

  useEffect(() => {
    if (mapView && locator) {
      applyClickHandlerToView();
      mapView.when(() => {
        onMapLoad(true);
      });
    }
  }, [mapView]);

  // handling suggestions based on address input
  const handleSearchInputChange = async () => {
    const isCoordinate = !!searchInput && /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)[,\s]\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g.test(searchInput);
    if (isCoordinate) {
      const latLong = searchInput.split(/[, ]+/g);
      const latitude = parseFloat(latLong[0]);
      const longitude = parseFloat(latLong[1]);
      const location = await getLocationPoint({ latitude, longitude });
      onSuggestionsChange([{
        text: `${latitude} ${longitude}`,
        location,
      }]);
    } else {
      const params = { text: searchInput };
      const suggestions = await locator.suggestLocations(locatorUrl, params);
      onSuggestionsChange(suggestions.map((suggestion) => ({
        text: suggestion.text,
        magicKey: suggestion.magicKey,
      })));
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearchInputChange();
    }
  }, [searchInput]);

  // handling selecting address from suggestions
  const handleAddressChange = async () => {
    if (selectedAddress.location) { // selected from exact inputted coordinates
      const params = { location: selectedAddress.location };
      const locationInfo = await locator.addressToLocations(locatorUrl, params);
      const {
        attributes: { LongLabel: address, CountryCode: countryCode, Region: state, City: city },
        location: { latitude, longitude },
      } = locationInfo;
      onLocationChange({
        address,
        countryCode,
        state,
        city,
        latitude,
        longitude,
      });
      mapView.goTo(
        [longitude, latitude],
        { duration: 1000 },
      );
    } else { // selected from suggestions by inputted address
      const addresses = await locator.addressToLocations(locatorUrl, {
        address: selectedAddress.text,
        magicKey: selectedAddress.magicKey,
        maxLocations: 1,
        outFields: ['Country, LongLabel, Region, City'],
      });
      const [{
        location: { longitude, latitude },
        attributes: { Country: countryCode, LongLabel: address, Region: state, City: city },
        extent,
      }] = addresses;
      onLocationChange({
        address,
        countryCode,
        state,
        city,
        longitude,
        latitude,
      });
      mapView.goTo(
        extent,
        { duration: 1000 },
      );
    }
  };

  useEffect(() => {
    if (selectedAddress) handleAddressChange();
  }, [selectedAddress]);

  // handling selecting location from suggestions
  const handleLocationChange = async () => {
    if (!selectedLocation) {
      graphicLayers.points && graphicLayers.points.removeAll();
      mapView && mapView.popup.close();
    } else {
      const { address, longitude, latitude } = selectedLocation;
      drawPoint(graphicLayers.points, { address, longitude, latitude });
      handleOpenPopup({ longitude, latitude, address });
    }
  };

  useEffect(() => {
    handleLocationChange();
  }, [selectedLocation]);

  // handling layers visibility
  const toggleLayer = () => {
    if (mapView) {
      Object.keys(layers).forEach((layerName) => { layers[layerName].layer.visible = false; });
      if (selectedLayer) {
        layers[selectedLayer].layer.visible = true;
      }

      mapView.ui.remove(legend);
      if (selectedLayer) {
        mapView.ui.add(legend, 'top-right');
      }
    }
  };

  useEffect(() => {
    toggleLayer();
    if (mapView) {
      applyClickHandlerToView();

      // refresh layer data in popup
      if (mapView.popup?.visible) {
        const { longitude, latitude } = mapView.popup.location;
        const address = mapView.popup.title;
        handleOpenPopup({ longitude, latitude, address });
      }
    }
  }, [selectedLayer]);

  // handling drawing project locations with map pins
  useEffect(() => {
    if (graphicLayers && projectLocations) {
      drawProjectLocations(graphicLayers.locations, projectLocations);
    }
  }, [projectLocations, graphicLayers]);

  if (!mapView) {
    return (
      <Wrapper>
        <Spinner />
        <StyledMap ref={mapRef} className={className} />
      </Wrapper>
    );
  }

  return (
    <Wrapper $querying={querying ? 1 : undefined}>
      <div />
      <StyledMap ref={mapRef} className={className} />
    </Wrapper>
  );
};

export default ProjectLocationMap;
