import { ModulePermissions } from 'types';

// TODO: adjust all permissions (done: locations, contacts, claims, time&expenses)
export const projects: ModulePermissions = {
  // all
  projects: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: false,
      read: true,
      update: true,
      delete: false,
    },
    Broker: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    InsuranceRepresentative: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RiskManager: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    DeveloperOwner: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    SiteContact: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RCCoordinator: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RiskEngineer: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
  },
  // ['ICCoordinator']
  add: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // all
  details: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: false,
      read: true,
      update: true,
      delete: false,
    },
    Broker: {
      read: true,
    },
    InsuranceRepresentative: {
      read: true,
    },
    RiskManager: {
      read: true,
    }, DeveloperOwner: {
      read: true,
    },
    RCCoordinator: {
      read: true,
    },
    RiskEngineer: {
      read: true,
      update: true,
    },
  },
  // all
  location: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    InsuranceRepresentative: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RiskManager: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    DeveloperOwner: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RCCoordinator: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // ['ICCoordinator', 'ICUnderwriter', 'RCCoordinator', 'RiskEngineer'],
  notes: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // all
  documents: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    InsuranceRepresentative: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskManager: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    DeveloperOwner: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    SiteContact: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // ['ICCoordinator', 'ICUnderwriter', 'Broker', 'RCCoordinator', 'RiskEngineer']
  contacts: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    InsuranceRepresentative: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // ['ICCoordinator']
  budget: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // ['ICCoordinator', 'ICUnderwriter']
  feeProposal: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // ['ICCoordinator', 'ICUnderwriter', 'RCCoordinator']
  resources: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // all
  recommendations: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    InsuranceRepresentative: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskManager: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    DeveloperOwner: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    SiteContact: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // all
  claims: {
    ICCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ICUnderwriter: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    InsuranceRepresentative: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RiskManager: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    DeveloperOwner: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RCCoordinator: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RiskEngineer: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
  },
  // ['RCCoordinator', 'RiskEngineer']
  expenses: {
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // ['RiskEngineer']
  'report-notes': {
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    Broker: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    RiskManager: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    SiteContact: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    InsuranceRepresentative: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },

  },
  // ['RiskEngineer']
  inspections: {
    RiskEngineer: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    RCCoordinator: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  // all, CRUD not needed
  analytics: {
    ICCoordinator: {},
    ICUnderwriter: {},
    Broker: {},
    InsuranceRepresentative: {},
    RiskManager: {},
    DeveloperOwner: {},
    SiteContact: {},
    RCCoordinator: {},
    RiskEngineer: {},
  },
  // ['RiskEngineer']
  reports: {
    RiskEngineer: {},
  },
  var: {
  },
};
// ['RCCoordinator']
// billing: {
//   RCCoordinator: {
//     create: true,
//     read: true,
//     update: true,
//     delete: true,
//   },
// },
