export enum SettingType {
  RecommendationApproval,
  ReportApproval,
  ProjectTabAccess = 'ProjectTabAccess',
}
interface RoleAccess {
  canOverride: boolean
}
export interface Setting {
  //   public string Name { get; set; }
  // public string Type { get; set; }
  // public string Scope { get; set; }
  // public string ScopeName { get; set; }
  // public object Value { get; set; }
  type: SettingType;
  name: string;
  scope: string;
  scopeName: string;
  value: boolean | {
    [tabKey: string]: { [roleKey: string]: RoleAccess };
  }
}
