export const layers = [
  {
    label: 'Flood',
    value: 'flood',
    tooltip: 'Average difference in precipitation (mm) between high-end rain events and the 99th percentile for a 20-year period of satellite-derived daily precipitation.',
  },
  {
    label: 'Wildfire',
    value: 'wildfire',
    tooltip: 'Global Wildfire Risk based on historical satellite derived burn area frequency from 2001 to 2020, enhanced with global land-cover insights.',
  },
  {
    label: 'Earthquake',
    value: 'earthquake',
    tooltip: 'Global Earthquake Risk hazard analysis based on 10% probability of exceedance in 50 years, return period of 475 years, in units of horizontal ground movement in units of gravity (m/s²).',
  },
  {
    label: 'Windstorm',
    value: 'windstorm',
    tooltip: 'Global Windstorm Risk represented with the 50-year peak wind gust speed (m/s) across the globe derived from 30-years of global reanalysis data.',
  },
  {
    label: 'Hurricane',
    value: 'hurricane',
    tooltip: 'This layer describes the observed path, forecast track, and intensity of tropical cyclone activity (hurricanes, typhoons, cyclones) from the National Hurricane Center (NHC) and Joint Typhoon Warning Center (JTWC).',
  },
];

export const comingSoonLayers = ['Lightning', 'Tornado', 'Hailstorm', 'Coastal Storm Surge', 'Tsunami', 'Landslide', 'Geology', 'Sinkholes', 'Riverine Flood Risk'];
