import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as ProjectsActions from 'projects/store/projects.reducer';
import {
  projectsList,
  projectsSortBy,
} from 'projects/store/projects.selectors';
import { formatPolicyDate } from 'utils/DateUtils';
import { formatCurrency, getCurrencySymbol } from 'utils/CurrencyUtils';
import AuthService, { ROLES_IDS } from 'services/AuthService';
import { PROJECT_STATUSES } from 'utils/constants';

import { Table, NoContent } from 'components';

import { ProjectListRoot, RiskScoreTag, StatusTag } from './styles';
import { COMPANY_TYPES } from 'types/company';

const renderStatusTitle = (value, readyToBeReallocated) => {
  const matchedStatus = PROJECT_STATUSES.find(
    (status) => status.value === value,
  );

  if (readyToBeReallocated) {
    return 'Reallocate';
  }
  return matchedStatus ? matchedStatus.viewLabel : `Unknown status ${value}`;
};

const BCColumns = [
  {
    width: '20%',
    key: 'name',
    title: 'Project Name',
    inTwoLines: true,
  },
  {
    width: '10%',
    key: 'status',
    title: 'Status',
    noSort: true,
    render: ({ status, readyToBeReallocated }) => (
      <StatusTag $status={status} $readyToBeReallocated={readyToBeReallocated}>
        {renderStatusTitle(status, readyToBeReallocated)}
      </StatusTag>
    ),
  },
  {
    width: '13%',
    key: 'country',
    title: 'Country',
  },
  {
    width: '12%',
    key: 'constructionValue',
    title: 'Construction Value',
    render: ({ constructionValue, currency }) => constructionValue &&
      `${getCurrencySymbol(currency)}${formatCurrency(constructionValue)}`,
  },
  {
    width: '11%',
    key: 'buildDate',
    title: 'Build Date',
    render: (data) => formatPolicyDate(data.buildDate),
  },
  {
    width: '13%',
    key: 'var',
    title: 'Value at Risk',
    render: ({ var: valueAtRisk, currency }) => valueAtRisk &&
      `${getCurrencySymbol(currency)}${formatCurrency(valueAtRisk)}`,
  },
  {
    width: '11%',
    key: 'riskScore',
    title: 'Risk Score',
    render: ({ riskScore }) => riskScore > 0 && <RiskScoreTag $riskScore={riskScore}>{riskScore}</RiskScoreTag>,
  },
  {
    width: '10%',
    key: 'referenceNumber',
    title: 'Reference Number',
    noSort: true,
  },
];

const ICColumns = [
  {
    width: '20%',
    key: 'name',
    title: 'Project Name',
    inTwoLines: true,
  },
  {
    width: '10%',
    key: 'status',
    title: 'Status',
    noSort: true,
    render: ({ status, readyToBeReallocated }) => (
      <StatusTag $status={status} $readyToBeReallocated={readyToBeReallocated}>
        {renderStatusTitle(status, readyToBeReallocated)}
      </StatusTag>
    ),
  },
  {
    width: '13%',
    key: 'country',
    title: 'Country',
  },
  {
    width: '12%',
    key: 'totalPremium',
    title: 'Total Premium',
    render: ({ totalPremium, currency }) => totalPremium &&
      `${getCurrencySymbol(currency)}${formatCurrency(totalPremium)}`,
  },
  {
    width: '9%',
    key: 'linePercent',
    title: 'Line',
    render: ({ linePercent }) => `${linePercent}%`,
  },
  {
    width: '13%',
    key: 'policyStartDate',
    title: 'Policy Start',
    render: (data) => data.policyStartDate && formatPolicyDate(data.policyStartDate),
  },
  {
    width: '13%',
    key: 'policyExpireDate',
    title: 'Policy End',
    render: (data) => data.policyExpireDate && formatPolicyDate(data.policyExpireDate),
  },
  {
    width: '10%',
    key: 'policyNumber',
    title: 'Policy Number',
    noSort: true,
  },
];

const brokerColumns = [
  {
    width: '13%',
    key: 'name',
    title: 'Project Name',
    inTwoLines: true,
  },
  {
    width: '10%',
    key: 'status',
    title: 'Status',
    noSort: true,
    render: ({ status, readyToBeReallocated }) => (
      <StatusTag status={status} readyToBeReallocated={readyToBeReallocated}>
        {renderStatusTitle(status, readyToBeReallocated)}
      </StatusTag>
    ),
  },
  {
    width: '17%',
    key: 'insuranceCompanyName',
    title: 'Client Company Name',
    noSort: true,
  },
  {
    width: '9%',
    key: 'country',
    title: 'Country',
  },
  {
    width: '13%',
    key: 'totalPremium',
    title: 'Total Premium',
    render: ({ totalPremium, currency }) => `${getCurrencySymbol(currency)}${formatCurrency(totalPremium)}`,
  },
  {
    width: '5%',
    key: 'linePercent',
    title: 'Line',
    render: ({ linePercent }) => `${linePercent}%`,
  },
  {
    width: '11%',
    key: 'policyStartDate',
    title: 'Policy Start',
    render: (data) => formatPolicyDate(data.policyStartDate),
  },
  {
    width: '13%',
    key: 'policyExpireDate',
    title: 'Policy End',
    render: (data) => formatPolicyDate(data.policyExpireDate),
  },
  {
    width: '9%',
    key: 'policyNumber',
    title: 'Policy Number',
    noSort: true,
  },
];

const defaultColumns = [
  {
    width: '14%',
    key: 'name',
    title: 'Project Name',
    inTwoLines: true,
  },
  {
    width: '10%',
    key: 'status',
    title: 'Status',
    noSort: true,
    render: ({ status, readyToBeReallocated }) => (
      <StatusTag status={status} readyToBeReallocated={readyToBeReallocated}>
        {renderStatusTitle(status, readyToBeReallocated)}
      </StatusTag>
    ),
  },
  {
    width: '21%',
    key: 'insuranceCompanyName',
    title: 'Client Company Name',
    noSort: true,
  },
  {
    width: '15%',
    key: 'country',
    title: 'Country',
  },
  {
    width: '15%',
    key: 'policyStartDate',
    title: 'Policy Start',
    render: (data) => formatPolicyDate(data.policyStartDate),
  },
  {
    width: '15%',
    key: 'policyExpireDate',
    title: 'Policy End',
    render: (data) => formatPolicyDate(data.policyExpireDate),
  },
  {
    width: '10%',
    key: 'policyNumber',
    title: 'Policy Number',
    noSort: true,
  },
];

const ProjectsListTable = ({ searchFilter }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const projects = useSelector(projectsList);
  const sortBy = useSelector(projectsSortBy);
  const { role, companyType } = AuthService.getUser();

  const isRiskEngineer = role === ROLES_IDS.RiskEngineer;

  const isICC = role === ROLES_IDS.ICCoordinator;
  const isICU = role === ROLES_IDS.ICUnderwriter;
  const isICRE = isRiskEngineer && companyType === COMPANY_TYPES.INSURANCE;
  const isBCRE = isRiskEngineer && companyType === COMPANY_TYPES.BROKER;
  const isSC = role === ROLES_IDS.SiteContact;
  const isBroker = role === ROLES_IDS.Broker || role === ROLES_IDS.InsuranceRepresentative;

  const isICMember = isICRE || isICC || isICU;
  const isBCMember = (isBCRE || isICC || isICU) && companyType === COMPANY_TYPES.BROKER;

  const defaultRoute = isSC ? 'documents' : 'details';

  const getColumns = () => {
    if (isBroker) return brokerColumns;
    if (isBCMember) return BCColumns;
    if (isICMember) return ICColumns;
    return defaultColumns;
  };

  function handleRowClick(item) {
    navigate(`/projects/${item.id}/${defaultRoute}`);
  }

  function handleSortBy(newSortBy) {
    dispatch(ProjectsActions.setProjectsSortBy(newSortBy));
  }

  function getProjectsList() {
    dispatch(ProjectsActions.getProjectsList({ page, sortBy, searchFilter }));
  }

  function handlePrevPageClick() {
    setPage(page - 1);
  }

  function handleNextPageClick() {
    setPage(page + 1);
  }

  function handleGoToPage(selectedPage) {
    setPage(selectedPage);
  }

  useEffect(() => {
    setPage(1);
  }, [searchFilter]);

  useEffect(() => {
    getProjectsList();
  }, [page, sortBy, searchFilter]);

  const { isLoading, items = [], totalPages, totalCount } = projects;

  function renderNoContent() {
    if (searchFilter) {
      return (
        <NoContent
          title="No results found."
          descr="Try adjust your search to find what you are looking for."
        />
      );
    }

    return (
      <NoContent
        title="There are no projects yet."
        descr='Click "Add Project" to start creating a new project.'
      />
    );
  }

  return (
    <ProjectListRoot>
      <Table
        items={items}
        columns={getColumns()}
        sortBy={sortBy}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        onSortBy={handleSortBy}
        onNoContent={renderNoContent}
        pagination={{
          page,
          totalPages,
          itemsCount: items.length,
          totalCount,
        }}
        onPrevPage={handlePrevPageClick}
        onNextPage={handleNextPageClick}
        onGoToPage={handleGoToPage}
      />
    </ProjectListRoot>
  );
};

export default ProjectsListTable;
