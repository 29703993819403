import { AxiosResponse } from 'axios';
import ApiClient, { Response } from 'services/ApiClient';
import AuthService from 'services/AuthService';
import { InsuranceCompanyInfoViewModel } from 'types';
import { COMPANY_TYPES } from 'types/company';
import { Setting } from 'types/setting';

class MyCompanyService extends ApiClient {
  private get principalCompanyPath() {
    return AuthService.getCompanyType() === COMPANY_TYPES.BROKER ? '/broker-companies' : '/insurance-companies';
  }

  async getInsuranceCompanyInfo(): Promise<AxiosResponse<Response<InsuranceCompanyInfoViewModel>>> {
    return this.call<InsuranceCompanyInfoViewModel>({
      method: 'get',
      url: `${this.principalCompanyPath}/current`,
    });
  }

  getInsuranceCompanyLocations() {
    return this.call({
      method: 'get',
      url: `${this.principalCompanyPath}/current/locations`,
    });
  }

  getRiskCompanyInfo() {
    return this.call({
      method: 'get',
      url: '/risk-companies/current',
    });
  }

  createInsuranceCompanyLocation(data: any) {
    return this.call({
      method: 'post',
      url: `${this.principalCompanyPath}/current/locations`,
      data,
    });
  }

  updateInsuranceCompanyInfo(data: any) {
    return this.call({
      method: 'put',
      url: `${this.principalCompanyPath}/current`,
      data,
    });
  }

  updateInsuranceCompanyLocationInfo(data: any) {
    return this.call({
      method: 'put',
      url: `${this.principalCompanyPath}/current/locations`,
      data,
    });
  }

  updateInsuranceCompanyPrimaryContactInfo(data: any) {
    return this.call({
      method: 'put',
      url: `${this.principalCompanyPath}/`,
      data,
    });
  }

  getInsuranceCompanyById(id: any) {
    return this.call({
      method: 'get',
      url: `${this.principalCompanyPath}/${id}`,
    });
  }

  getInsuranceCompanyCoordinatorsById(id: string) {
    return this.call({
      method: 'get',
      url: `${this.principalCompanyPath}/${id}/coordinators`,
    });
  }

  getBrokerCompanyById(id: string) {
    return this.call({
      method: 'get',
      url: `/broker-companies/${id}`,
    });
  }

  deleteInsuranceCompanyById(id: string) {
    return this.call({
      method: 'delete',
      url: `${this.principalCompanyPath}/${id}`,
    });
  }

  deleteInsuranceCompanyLocationById(id: string) {
    return this.call({
      method: 'delete',
      url: `${this.principalCompanyPath}/current/location/${id}`,
    });
  }

  getRiskCompanyById(id: string) {
    return this.call({
      method: 'get',
      url: `/risk-companies/${id}`,
      data: {
        id,
      },
    });
  }

  updateRiskCompanyInfo(data: string) {
    return this.call({
      method: 'put',
      url: '/risk-companies/current',
      data,
    });
  }

  getRiskCompanyCoordinators() {
    return this.call({
      method: 'get',
      url: '/risk-companies/current/coordinators',
    });
  }

  getInsuranceCompanyCoordinators() {
    return this.call({
      method: 'get',
      url: `${this.principalCompanyPath}/current/coordinators`,
    });
  }

  getInsuranceCompanySettings(): Promise<AxiosResponse<Response<Setting[]>>> {
    return this.call<Setting[]>({
      method: 'get',
      url: `${this.principalCompanyPath}/current/settings`,
    });
  }

  updateInsuranceCompanySettings(data: any, suffix:
  'bool' | 'access' = 'bool') {
    return this.call({
      method: 'put',
      url: `${this.principalCompanyPath}/current/settings/${suffix}`,
      data,
    });
  }

  downloadData(isRCC: boolean) {
    const url = isRCC ? '/risk-companies/current/download-data' : `${this.principalCompanyPath}/current/download-data`;

    return this.call({
      method: 'GET',
      url,
      responseType: 'blob',
    });
  }
}

export default new MyCompanyService();
