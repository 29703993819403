import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';

import Spinner from 'components/Spinner';

import { useMap } from './loadHelpers';
import { drawProjectsData, drawGraphic, drawManyGraphics, getTivRoundMarker, getTivTextSymbol } from './graphicsHelpers';
import { getRiskScorePopupContent, getRiskLayerQuery, interactiveLayers } from './constants';

import { StyledMap, Wrapper } from './styles';
import AuthService from 'services/AuthService';
import { COMPANY_TYPES } from 'types/company';

const TivVar = (props) => {
  const { className, data, onMapLoad, selectedLayer } = props;
  const companyType = AuthService.getCompanyType();
  const isBrokerCompany = companyType === COMPANY_TYPES.BROKER;
  const theme = useTheme();

  const loadOptions = {
    view: {
      center: [-47, 45],
      zoom: 2,
    },
    selectedLayer,
    isTivVar: true,
  };
  const isInteractiveLayer = interactiveLayers.includes(selectedLayer);

  const [mapRef, mapView, layers, graphicsLayers, legend] = useMap(loadOptions);
  const [zoomLevel, setZoomLevel] = useState(null);
  const [hoverListener, setHoverListener] = useState();
  const [clickListener, setClickListener] = useState();
  const [querying, setQuerying] = useState(false);

  const zoomedToProjects = zoomLevel > 3;
  const projects = data.flatMap((country) => country.projects);
  const mapType = zoomedToProjects ? 'tivVarProjects' : 'tivVarCountries';
  const mapData = zoomedToProjects ? projects : data;

  const handleOpenPopup = async (location) => {
    const { longitude, latitude } = location;
    let popupContent;
    if (isInteractiveLayer) {
      setQuerying(true);
      const queryResult = await layers[selectedLayer].layer
        .queryFeatures(getRiskLayerQuery(location));
      const { riskScore, riskDesc, color } = queryResult?.features[0]?.attributes;
      popupContent = `
            ${Number(latitude).toFixed(6)} ${Number(longitude).toFixed(6)}\n
            ${getRiskScorePopupContent(selectedLayer, riskScore, riskDesc, color)}
          `;
      setQuerying(false);
      mapView.popup.open({
        location: { longitude, latitude },
        title: 'Risk Score',
        content: popupContent,
      });
    }
  };

  const zoomTo = (target) => {
    mapView.goTo(
      { target, zoom: 4 },
      { duration: 500, easing: 'ease-out' },
    );
  };

  const applyZoomWatcherToView = () => {
    mapView.watch('zoom', (newValue) => {
      setZoomLevel(newValue);
    });
  };

  const applyPopupHandlerToView = () => {
    if (mapView.popup) {
      mapView.popup.on('trigger-action', (event) => {
        if (event.action.id === 'zoomTo' && mapView.popup.selectedFeature.geometry) {
          zoomTo(mapView.popup.selectedFeature.geometry);
          graphicsLayers.points.removeAll();
          mapView.popup.close();
        }
      });
    }
  };

  const applyHoverHandler = async () => {
    if (zoomedToProjects) {
      const listener = mapView.on('pointer-move', async (event) => {
        const { results } = await mapView.hitTest(event);
        const hoveredResult = results.find((result) => result.graphic.layer?.id === 'locations' && result.graphic.attributes);
        const hoveredDotColor = hoveredResult ? hoveredResult.graphic.attributes.dotColor : '#96CEF6';
        const isHoverOverPoint = !!hoveredResult;
        const isPointSelected = results.some((result) => result.graphic.layer?.id === 'points');

        if (isHoverOverPoint) {
          const { longitude, latitude } = results[0].graphic.geometry;
          const geometry = {
            type: 'point',
            longitude,
            latitude,
          };
          const defaultHoveredColor = theme.light ? '#FFFFFF' : '#343640';
          const dotColor = isPointSelected ? '#FFF' : hoveredDotColor;
          const outlineColor = isPointSelected ? hoveredDotColor : defaultHoveredColor; // if reverse colors needed
          const symbol = {
            type: 'simple-marker',
            size: '20px',
            color: dotColor,
            outline: {
              color: outlineColor,
              width: 4.5,
            },
          };

          drawGraphic(graphicsLayers.hover, { geometry, symbol });
        } else {
          graphicsLayers.hover.removeAll();
        }
      });
      setHoverListener(listener);
    } else if (hoverListener) {
      hoverListener.remove();
    }
  };

  const applyClickHandler = async () => {
    if (clickListener) {
      clickListener.remove();
    }
    const listener = mapView.on('click', async (event) => {
      const { results } = await mapView.hitTest(event);
      const locationClickResult = results.find((result) => result.graphic.layer?.id === 'locations' && result.graphic.attributes);
      const isClickOnLocation = !!locationClickResult;

      if (isClickOnLocation) {
        const { longitude, latitude } = locationClickResult.graphic.geometry;
        const geometry = {
          type: 'point',
          longitude,
          latitude,
        };
        if (zoomedToProjects) {
          const hoveredDotColor = locationClickResult ? locationClickResult.graphic.attributes.dotColor : '#96CEF6';
          const symbol = {
            type: 'simple-marker',
            color: '#FFF',
            size: '16px',
            outline: {
              color: hoveredDotColor,
              width: 4.5,
            },
          };
          graphicsLayers.hover.removeAll();
          drawGraphic(graphicsLayers.points, { geometry, symbol });
        } else {
          const { markerColor, textColor, markerSize, fontSize, displayedTiv } = locationClickResult.graphic.attributes;
          const selectionSymbol = {
            type: 'simple-marker',
            color: 'rgba(255, 255, 255, 0.5);',
            size: markerSize + 15,
            outline: { width: 0 },
          };
          const circleSymbol = getTivRoundMarker(markerSize, markerColor);
          const textSymbol = getTivTextSymbol(fontSize, textColor, displayedTiv);
          const symbolsArray = [
            { geometry, symbol: selectionSymbol },
            { geometry, symbol: circleSymbol },
            { geometry, symbol: textSymbol },
          ];
          drawManyGraphics(graphicsLayers.points, symbolsArray);
        }
      } else {
        graphicsLayers.points.removeAll();
        handleOpenPopup(event.mapPoint);
      }
    });
    setClickListener(listener);
  };

  const toggleLayer = () => {
    if (mapView) {
      Object.keys(layers).forEach((layerName) => { layers[layerName].layer.visible = false; });
      if (selectedLayer) {
        layers[selectedLayer].layer.visible = true;
      }

      mapView.ui.remove(legend);
      if (selectedLayer) {
        mapView.ui.add(legend, 'top-right');
      }
    }
  };
  useEffect(() => {
    toggleLayer();
    if (mapView) {
      applyClickHandler();

      // refresh layer data in popup
      if (mapView.popup?.visible && !mapView.popup?.featureCount) {
        const { longitude, latitude } = mapView.popup.location;
        handleOpenPopup({ longitude, latitude });
      }
    }
  }, [selectedLayer]);

  useEffect(() => {
    if (mapView) {
      applyClickHandler();
      applyHoverHandler();
      applyPopupHandlerToView();
      applyZoomWatcherToView();
      onMapLoad(mapView);
    }
  }, [mapView]);

  useEffect(() => {
    if (mapView) {
      applyClickHandler();
      applyHoverHandler();
    }
  }, [zoomedToProjects]);

  // handling drawing data on map
  useEffect(() => {
    if (graphicsLayers.locations && data) {
      drawProjectsData(mapData, graphicsLayers.locations, mapType, theme, isBrokerCompany);
    }
  }, [data, graphicsLayers, zoomedToProjects]);

  if (!mapView) {
    return (
      <Wrapper>
        <Spinner />
        <StyledMap ref={mapRef} className={className} isGlobal />
      </Wrapper>
    );
  }

  return (
    <Wrapper $querying={querying ? 1 : undefined}>
      <div />
      <StyledMap ref={mapRef} className={className} isGlobal />
    </Wrapper>
  );
};

export default TivVar;
