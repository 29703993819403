import React from 'react';
import {
  MenuItem as MuiMenuItem,
  Tooltip as MuiTooltip,
} from '@mui/material';

import { IconWrapper, TextWrapper, MenuItemWrapper, StyledIcon, TooltipBlock } from './styles';

const MenuItem = ({
  icon,
  text,
  onClick,
  disabled,
  tooltip,
}) => (
  <MenuItemWrapper>
    <MuiMenuItem
      onClick={onClick}
      disabled={disabled}
    >
      <IconWrapper>
        <StyledIcon icon={icon} noWrapper />
      </IconWrapper>
      <TextWrapper>
        {text}
      </TextWrapper>
    </MuiMenuItem>

    {tooltip && (
      <MuiTooltip arrow title={tooltip}>
        <TooltipBlock />
      </MuiTooltip>
    )}
  </MenuItemWrapper>
);

export default MenuItem;
