import styled from 'styled-components';
import { styled as styledMui } from '@mui/material/styles';
import { Form } from 'formik';

export const DataWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 125px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 48px;
  width: 95%;
  margin-bottom: 24px;

  > div {
    margin-right: 15px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 15px;
  }
`;

export const Title = styledMui('div')(({ theme }) => {
  return {
    marginBottom: 24,
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '0.16px',
    color: theme.palette.text.secondary,
  };
});
