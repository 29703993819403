import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";

import Icon from "components/Icon";
import { Accordion } from "@mui/material";

export const StyledAccordion = styledMui(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    "&:before": {
      backgroundColor: "transparent",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
    "> .MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 0,
      background: theme.palette.accordion.header,
      borderRadius: 4,
      margin: "1px 0 0",
      "&.Mui-expanded": {
        minHeight: 0,
      },
      "> .MuiAccordionSummary-content": {
        margin: 0,
        "&.Mui-expanded": {
          margin: 0,
        },
      },

      "> .MuiAccordionSummary-expandIconWrapper": {
        position: "absolute",
        right: 10,
      },
    },
  },
  ".MuiAccordionDetails-root": {
    padding: 0,
  },
}));

export const StyledIcon = styledMui(Icon)(({ theme }) => ({
  svg: {
    path: {
      fill: theme.palette.icon.primary,
    },
  },
}));

export const FullWidthWrapper = styled.div`
  width: 100%;
`;

export const TableRoot = styled.div`
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .MuiIconButton-edgeEnd {
    margin-right: -6px;
  }
  .MuiAccordionSummary-root {
    padding-right: 25px;
    margin: 0;
    border-radius: 5px;
    margin-bottom: 10px;
    .MuiAccordionSummary-content {
      margin: 0;
    }
  }
`;

export const Row = styledMui("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  height: 60,
  paddingLeft: 24,
  cursor: "pointer",
  fontFamily: "Graphik LC",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: 13,
  lineHeight: "138.5%",
  color: theme.palette.text.main,
  border: "1px solid transparent",
}));

export const Cell = styled.div`
  position: relative;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
