import { capitalizeAll } from 'utils/FormatUtils';

const hurricaneUrl = 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/Active_Hurricanes_v1/FeatureServer';
// hurricanes sample, will be removed later:
// const hurricaneUrl = 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/arcgis/rest/services/Active_Hurricanes_Sampler/FeatureServer';

export const interactiveLayers = ['flood', 'wildfire', 'earthquake', 'windstorm'];

export const layers = {
  flood: {
    name: 'flood',
    id: 'b1d96745f3e54b7db878a62dafe745e6',
    url: 'https://services1.arcgis.com/jpaPdLdiSRtjob8x/arcgis/rest/services/Global_SynopticFlood_Risk_2024/FeatureServer',
    layer: null,
  },
  wildfire: {
    name: 'wildfire',
    id: '2a3937dd6b4f4cd38ddb43b5450a1ba1',
    url: 'https://services1.arcgis.com/jpaPdLdiSRtjob8x/arcgis/rest/services/Global_Fire_Risk_2024/FeatureServer',
    layer: null,
  },
  earthquake: {
    name: 'earthquake',
    id: 'cae104ac6e334d8cb0bec23aa9000930',
    url: 'https://services1.arcgis.com/jpaPdLdiSRtjob8x/arcgis/rest/services/Global_Earthquake_Risk_2024/FeatureServer',
    layer: null,
  },
  windstorm: {
    name: 'windstorm',
    id: '4e5a83b0dbb043d5b704bb6a28a21b3b',
    url: 'https://services1.arcgis.com/jpaPdLdiSRtjob8x/arcgis/rest/services/Global_Wind_Risk_2024/FeatureServer',
    layer: null,
  },
  hurricane: {
    name: 'hurricane',
    id: '248e7b5827a34b248647afb012c58787',
    url: hurricaneUrl,
    layer: null,
    subLayers: [
      { url: `${hurricaneUrl}/11`, legendEnabled: true }, // Observed Wind Swath
      { url: `${hurricaneUrl}/10`, legendEnabled: false }, // Raw 1/10th Degree Data (All)
      { url: `${hurricaneUrl}/9`, legendEnabled: false }, // Hurricane Force (64kts+)
      { url: `${hurricaneUrl}/8`, legendEnabled: false }, // Strong Tropical Storm (50kts)
      { url: `${hurricaneUrl}/7`, legendEnabled: true }, // Tropical Storm Force (34kts)
      // 6 is missing
      { url: `${hurricaneUrl}/5`, legendEnabled: true }, // Watches and Warnings
      { url: `${hurricaneUrl}/4`, legendEnabled: true }, // Forecast Error Cone
      { url: `${hurricaneUrl}/3`, legendEnabled: true }, // Observed Track
      { url: `${hurricaneUrl}/2`, legendEnabled: false }, // Forecast Track
      { url: `${hurricaneUrl}/1`, legendEnabled: false }, // Observed Position
      { url: `${hurricaneUrl}/0`, legendEnabled: true }, // Forecast Position
    ],
  },
};

const getLayerMarkerColor = (layer) => {
  switch (layer) {
    case 'flood':
      return '#0f6b72';
    case 'wildfire':
      return '#d2790e';
    case 'earthquake':
      return '#b52d75';
    case 'windstorm':
      return '#5292c9';
    default:
      return '#000';
  }
};

export const calculateRiskScore = (value) => {
  if (value <= 3) return 0;
  switch (value) {
    case 4:
      return 6;
    case 5:
      return 8;
    case 6:
    case 7:
    case 8:
    case 9:
      return value * 2;
    default:
      return 20;
  }
};

export const getRiskScorePopupContent = (layer, score, description, color) => {
  return `
  <div class="risk-score">
    <div>
      <div class="marker" style="background-color: ${getLayerMarkerColor(layer)}"></div>
      <span>${capitalizeAll(layer)}</span>
    </div>
    <div>
      <span>Score: </span>
  <span><b>${calculateRiskScore(score)}</b></span>
    </div>
    <div>
      <span>${capitalizeAll(description)}</span>
      <div class="marker-sq" style="background-color: ${color}"></div>
    </div>
  </div>
`;
};

export const getRiskLayerQuery = ({ longitude, latitude }) => ({
  spatialRelationship: 'intersects',
  geometry: { longitude, latitude, type: 'point' },
  outFields: ['riskScore', 'riskDesc', 'color'],
  // outFields: ['*'],
});
