import React, { useState, useEffect } from "react";

import Switch from "components/Switch";
import { showNotifySuccess } from "services/toaster";
import ConfirmationDialog from "components/Dialogs/ConfirmationDialog";
import { Column, Root, Row, SubTitle, Title } from "./styles";
import MyCompanyService from "../../api/MyCompanyService";
import { SettingType } from "types/setting";
import Select from "components/Select";
import AuthService, { ROLES_LIST } from "services/AuthService";
import { COMPANY_TYPES } from "types/company";
import { ROLE } from "types/user";
import { PROJECTS_TABS } from "components/Navbar/projectTabs";

const scopeSettings = (settings) => {
  const scopes = [...new Set(settings.map((t) => t.scope))];
  const scopesWithSettings = scopes.map((t) => {
    const settingsByScope = settings
      .filter((s) => s.scope === t)
      .map((s) => ({ ...s, name: s.name.replace("Underwriter’s ", "") }));
    const scope = settings.find((s) => s.scope === t)?.scopeName;
    return {
      type: t == null ? settingsByScope[0].type : t,
      name: scope,
      settings: settingsByScope,
    };
  });
  return scopesWithSettings;
};

const Settings = () => {
  const [settings, setSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const scopedSettings = scopeSettings(settings);
  const companyType = AuthService.getCompanyType();
  const isOp = companyType === COMPANY_TYPES.BROKER;

  const toggleShowWarning = (value = false) => {
    setShowWarning(value);
  };

  const loadSettings = async () => {
    const { data } = await MyCompanyService.getInsuranceCompanySettings();
    setSettings(data.data);
  };

  const updateSetting = async (setting, newValue, suffix) => {
    await MyCompanyService.updateInsuranceCompanySettings(
      {
        id: setting.id,
        value: newValue,
      },
      suffix
    );
    showNotifySuccess("Setting has been updated");
    const newSettings = settings.map((t) => {
      if (t.id === setting.id) {
        return { ...t, value: newValue };
      }
      return t;
    });
    toggleShowWarning(false);
    setSettings(newSettings);
    setSelectedSetting(null);
  };

  const onChangeSetting = async (setting, value) => {
    if (setting.warningText && value === false) {
      setSelectedSetting({ setting, value });
      toggleShowWarning(true);
    } else {
      await updateSetting(setting, value);
    }
  };
  useEffect(() => {
    loadSettings();
  }, []);

  const renderBoolSetting = (scope) => {
    return (
      <Row key={scope.type}>
        <Column>
          <Row key={scope.type}>
            <Title>{scope.name}</Title>
          </Row>
          {scope.settings.map((setting) => (
            <Row key={setting.id}>
              <Column>
                <SubTitle>{setting.name}</SubTitle>
              </Column>
              <Column>
                <Switch
                  value={setting.value}
                  onChange={(event) =>
                    onChangeSetting(setting, event.target.checked)
                  }
                />
              </Column>
            </Row>
          ))}
        </Column>
      </Row>
    );
  };

  const renderAccessSetting = () => {
    if (settings == null || settings.length === 0) return null;
    const setting = settings.find(
      (s) => s.type === SettingType.ProjectTabAccess
    );
    if (setting == null) return null;
    return (
      <Row key={setting.type}>
        <Column>
          <Row key={setting.type}>
            <Title>Project's Tab Access</Title>
          </Row>
          {Object.keys(setting.value).map((tabAccessKey) => (
            <Row key={setting.id}>
              <Column>
                <SubTitle>
                  {PROJECTS_TABS.find((tab) => tab.name === tabAccessKey)?.label}
                </SubTitle>
              </Column>
              <Column>
                <Select
                  label="Role"
                  multiple
                  value={Object.keys(setting.value[tabAccessKey])}
                  options={ROLES_LIST.filter(
                    (role) => role.id !== ROLE.ADMIN && role.id !== ROLE.NONE
                  ).map((role) => {
                    if (role.id ===  ROLE.RC_COORDINATOR && isOp) {
                      return {
                        label: "Risk Coordinator",
                        value: role.id,
                        disabled: setting.value[tabAccessKey][role.id] &&
                        !setting.value[tabAccessKey][role.id]?.canBeOverriden
                      }
                    }
                    return {
                      label: isOp ? role.brokerCompanyName : role.name,
                      value: role.id,
                      disabled:
                        setting.value[tabAccessKey][role.id] &&
                        !setting.value[tabAccessKey][role.id]?.canBeOverriden,
                    };
                  })}
                  onChange={(event) => {
                    const newSetting = {
                      ...setting,
                      value: {
                        ...setting.value,
                        [tabAccessKey]: event.target.value.reduce(
                          (acc, role) => {
                            acc[role] = setting.value[tabAccessKey][role] || {
                              canBeOverriden: true,
                            };
                            return acc;
                          },
                          {}
                        ),
                      },
                    };
                    updateSetting(setting, newSetting.value, "access");
                  }}
                ></Select>
              </Column>
            </Row>
          ))}
        </Column>
      </Row>
    );
  };

  return (
    <Root>
      {scopedSettings
        .filter((s) => Boolean(s.name))
        .map((scope) => renderBoolSetting(scope))}
      {renderAccessSetting()}
      {showWarning && (
        <ConfirmationDialog
          open
          title={selectedSetting.setting.name}
          confirmButtonTitle="Yes"
          cancelButtonTitle="No"
          onClose={() => toggleShowWarning(false)}
          onCancel={() => toggleShowWarning(false)}
          onConfirm={() =>
            updateSetting(selectedSetting.setting, selectedSetting.value)
          }
        >
          {selectedSetting.setting.warningText}
          <br />
          Do you want to continue?
        </ConfirmationDialog>
      )}
    </Root>
  );
};

export default Settings;
